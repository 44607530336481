@font-face {
    font-family: 'PP Neue Montreal';
    src: url('./PPNeueMontreal-Regular.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
    font-size: normal;
}

@font-face {
    font-family: 'PP Neue Montreal';
    src: url('./PPNeueMontreal-Medium.woff2') format('woff2');
    font-weight: medium;
    font-style: normal;
    font-size: normal;
}