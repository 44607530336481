@font-face {
  font-family: 'Circular Std';
  src: url('https://sdk.selfbook.com/assets/CircularXXSub-BookSubset.woff2') format('woff2');
  font-size: normal;
  font-weight: 400;
}

@font-face {
  font-family: 'Circular Std';
  src: url('https://sdk.selfbook.com/assets/CircularXXSub-LightSubset.woff2') format('woff2');
  font-size: normal;
  font-weight: 300;
}

body,
html {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background: #EEF0F2;
  height: 100%;
  width: 100%;
  display: flex;
}

#root {
  width: 100%;
}

.gpay-button {
  height: 48px !important;
  border: 1px solid #adb5bd !important;
  border-radius: 4px !important;
  box-shadow: none !important;
  font-size: 16px !important;
}

apple-pay-button {
  --apple-pay-button-height: 48px;
  --apple-pay-button-border-radius: 4px;
  --apple-pay-button-padding: 4px 32px;
  --apple-pay-button-box-sizing: border-box;
  flex: 1;
}

/* Date picker custom styles */
.rdp {
  --rdp-cell-size: 47px !important;
  --rdp-outline: 1px solid #212529;
  font-family: 'Circular Std';
  margin: 0 !important;
}

.rdp-day {
  width: 47.43px !important;
  height: 52px !important;
  border-radius: 0 !important;
  border: 0 !important;
  margin: 2px !important;
  background-color: #dee2e6 !important;
  font-weight: 450 !important;
  font-size: 16px !important;
  line-height: 130% !important;
  text-align: center !important;
  letter-spacing: 0.01em !important;
  color: #343a40 !important;
}

.rdp-day_selected {
  border-radius: 0 !important;
  color: #343a40 !important;
  background-color: #fff !important;
  border: 1px solid #343a40 !important;
}
.rdp-day:hover,
.rdp-day:focus,
.rdp-day:focus-visible,
.rdp-day:focus-within {
  width: 47.43px !important;
  height: 52px !important;
  border-radius: 0 !important;
  background-color: #495057 !important;
  color: #ffffff !important;
}

.rdp-cell {
  border-collapse: unset !important;
}

.rdp-head_cell {
  text-transform: initial !important;
  color: #6c757d !important;
  font-weight: 450 !important;
  font-size: 14px !important;
  line-height: 140% !important;
  text-align: center !important;
  letter-spacing: 0.01em !important;
}

/* FullCalendar custom styles */
.fc-h-event {
  display: flex;
  height: 85px !important;
  border: 2px solid #3788d8;
  font-family: 'Circular Std';
  border-radius: 4px;
}

.fc-timeline-header-row th {
  border-top: 0 !important;
  border-left: 0px !important;
  border-right: 0px !important;
}

.fc-timeline-header {
  border-top: 0 !important;
  border-right: 0px !important;
}

.fc .fc-scrollgrid {
  border-collapse: unset;
  border-right-width: unset;
  border-bottom-width: unset;
}

.fc-theme-standard .fc-scrollgrid {
  border: unset !important;
}

.fc .fc-timeline-slot-frame {
  margin-bottom: 10px !important;
}
.fc table {
  border-collapse: collapse;
  border-spacing: unset;
  font-size: unset;
}

.fc-timelineMonth-view table {
  border: 0 !important;
}

.fc-scroller-liquid-absolute::-webkit-scrollbar {
  width: 0 !important;
}

.fc-scroller::-webkit-scrollbar {
  width: 0 !important;
}

.fc-scrollgrid-section-sticky th {
  border-right: 0 !important;
  border-top: 0 !important;
  border-left: 0 !important;
}

.fc-timeline-event-harness {
  padding: 0px !important;
  padding-left: 10.8px !important;
}

.fc-timeline-event {
  padding: 0px !important;
  border: 0px;
}

.reservation-status-wrapper-paid,
.reservation-status-wrapper-partial,
.reservation-status-wrapper-failed,
.reservation-status-wrapper-unpaid {
  margin-top: 8px;
  border-radius: 4px !important;
  cursor: pointer;
}

.fc .fc-timeline-overlap-enabled .fc-timeline-lane-frame .fc-timeline-events {
  padding-bottom: 0px !important;
}

.fc-scroller-liquid-absolute {
  border-left: 1px solid #dee2e6 !important;
}

.fc-day-today a {
  background-color: #f0f2f4;
  width: 100%;
  margin-bottom: 5px !important;
  border-radius: 4px;
}

.fc-timeline-slot-lane.fc-day-today {
  background-color: #f0f2f4;
}

.fc-timeline-body td,
.fc-timeline-body th {
  border: 1px solid #dee2e6 !important;
}

.fc-timeline-slot-lane {
  border: 0px !important;
}

dl {
  margin: 0;
}

.fc-timeline-event:not(.fc-event-end) {
  margin-right: 26px !important;
}

.fc-timeline-event:not(.fc-event-start) {
  margin-left: 10px !important;
}

.fc-timeline-event:not(.fc-event-end):after {
  width: 25px !important;
  height: 100% !important;
  position: relative !important;
  left: 25px !important;
  border: 0px !important;
  opacity: 1 !important;
  margin: 0 3px !important;
}

.fc-timeline-event:not(.fc-event-start):before {
  width: 25px !important;
  height: 100% !important;
  position: relative !important;
  left: -20px !important;
  border: 0px !important;
  opacity: 1 !important;
  transform: rotate(180deg) !important;
}

.fc-timeline-event:not(.fc-event-start) .fc-event-main > div {
  padding-left: 0px !important;
  margin-left: -10px;
  width: 100%;
}

.fc-timeline-event:not(.fc-event-end) .fc-event-main > div {
  margin-left: 8px;
  width: 100%;
}

/* Right side end */
.reservation-status-wrapper-paid:not(.fc-event-end):after {
  background-image: url("data:image/svg+xml,%3Csvg width='25' height='85' viewBox='0 0 25 85' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M23.6248 39.3225C24.5023 41.35 24.5023 43.65 23.6248 45.6775L8.69368 80.1775C7.4267 83.105 4.54166 85 1.35177 85L7.62939e-06 85L1.98459e-07 2.18557e-06L1.35176 2.06739e-06C4.54166 1.78852e-06 7.42669 1.89503 8.69367 4.82252L23.6248 39.3225Z' fill='%23CEECDA'/%3E%3C/svg%3E%0A") !important;
}

.reservation-status-wrapper-partial:not(.fc-event-end):after {
  background-image: url("data:image/svg+xml,%3Csvg width='25' height='85' viewBox='0 0 25 85' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M23.6248 39.3225C24.5023 41.35 24.5023 43.65 23.6248 45.6775L8.69368 80.1775C7.4267 83.105 4.54166 85 1.35177 85L7.62939e-06 85L1.98459e-07 2.18557e-06L1.35176 2.06739e-06C4.54166 1.78852e-06 7.42669 1.89503 8.69367 4.82252L23.6248 39.3225Z' fill='%23FFEFC4'/%3E%3C/svg%3E%0A") !important;
}

.reservation-status-wrapper-failed:not(.fc-event-end):after {
  background-image: url("data:image/svg+xml,%3Csvg width='25' height='85' viewBox='0 0 25 85' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M23.6248 39.3225C24.5023 41.35 24.5023 43.65 23.6248 45.6775L8.69368 80.1775C7.4267 83.105 4.54166 85 1.35177 85L7.62939e-06 85L1.98459e-07 2.18557e-06L1.35176 2.06739e-06C4.54166 1.78852e-06 7.42669 1.89503 8.69367 4.82252L23.6248 39.3225Z' fill='%23FCEAEA'/%3E%3C/svg%3E%0A") !important;
}

.reservation-status-wrapper-unpaid:not(.fc-event-end):after {
  background-image: url("data:image/svg+xml,%3Csvg width='25' height='85' viewBox='0 0 25 85' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M23.6248 39.3225C24.5023 41.35 24.5023 43.65 23.6248 45.6775L8.69368 80.1775C7.4267 83.105 4.54166 85 1.35177 85L7.62939e-06 85L1.98459e-07 2.18557e-06L1.35176 2.06739e-06C4.54166 1.78852e-06 7.42669 1.89503 8.69367 4.82252L23.6248 39.3225Z' fill='%23F0F2F4'/%3E%3C/svg%3E%0A") !important;
}

/* Left side end */
.reservation-status-wrapper-paid:not(.fc-event-start):before {
  background-image: url("data:image/svg+xml,%3Csvg width='25' height='85' viewBox='0 0 25 85' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M23.6248 39.3225C24.5023 41.35 24.5023 43.65 23.6248 45.6775L8.69368 80.1775C7.4267 83.105 4.54166 85 1.35177 85L7.62939e-06 85L1.98459e-07 2.18557e-06L1.35176 2.06739e-06C4.54166 1.78852e-06 7.42669 1.89503 8.69367 4.82252L23.6248 39.3225Z' fill='%23CEECDA'/%3E%3C/svg%3E%0A") !important;
}

.reservation-status-wrapper-unpaid:not(.fc-event-start):before {
  background-image: url("data:image/svg+xml,%3Csvg width='25' height='85' viewBox='0 0 25 85' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M23.6248 39.3225C24.5023 41.35 24.5023 43.65 23.6248 45.6775L8.69368 80.1775C7.4267 83.105 4.54166 85 1.35177 85L7.62939e-06 85L1.98459e-07 2.18557e-06L1.35176 2.06739e-06C4.54166 1.78852e-06 7.42669 1.89503 8.69367 4.82252L23.6248 39.3225Z' fill='%23F0F2F4'/%3E%3C/svg%3E%0A") !important;
}

.reservation-status-wrapper-failed:not(.fc-event-start):before {
  background-image: url("data:image/svg+xml,%3Csvg width='25' height='85' viewBox='0 0 25 85' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M23.6248 39.3225C24.5023 41.35 24.5023 43.65 23.6248 45.6775L8.69368 80.1775C7.4267 83.105 4.54166 85 1.35177 85L7.62939e-06 85L1.98459e-07 2.18557e-06L1.35176 2.06739e-06C4.54166 1.78852e-06 7.42669 1.89503 8.69367 4.82252L23.6248 39.3225Z' fill='%23FCEAEA'/%3E%3C/svg%3E%0A") !important;
}

.reservation-status-wrapper-partial:not(.fc-event-start):before {
  background-image: url("data:image/svg+xml,%3Csvg width='25' height='85' viewBox='0 0 25 85' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M23.6248 39.3225C24.5023 41.35 24.5023 43.65 23.6248 45.6775L8.69368 80.1775C7.4267 83.105 4.54166 85 1.35177 85L7.62939e-06 85L1.98459e-07 2.18557e-06L1.35176 2.06739e-06C4.54166 1.78852e-06 7.42669 1.89503 8.69367 4.82252L23.6248 39.3225Z' fill='%23FFEFC4'/%3E%3C/svg%3E%0A") !important;
}

.fc-event:focus {
  box-shadow: none !important;
}

.fc-event:focus:after {
  background-color: transparent !important;
  box-shadow: none !important;
}

.fc-scrollgrid-section th,
.fc-scrollgrid-section td {
  border-right: 0 !important;
}

.fc .fc-timeline-slots > table {
  border-right: 2px solid #dee2e6 !important;
}

.fc-scrollgrid-sync-table td {
  border-bottom: 1px solid transparent !important;
}

.fc-resource-timeline-divider {
  background: transparent !important;
  width: 0px !important;
}

.expanded-tab + .activity-tab {
  margin-top: -86px;
  height: 86px;
  transition-property: height, margin-top;
  transition-duration: 200ms, 197ms;
  transition-delay: 0ms, 2ms;
  transition-timing-function: ease-in-out;
}

.expanded-tab-has-desc + .activity-tab {
  margin-top: -109px;
  height: 109px;
  transition-property: height, margin-top;
  transition-duration: 200ms, 197ms;
  transition-delay: 0ms, 2ms;
  transition-timing-function: ease-in-out;
}

input::-webkit-textfield-decoration-container {
  visibility: hidden;
}
